$font-size-main: 16;

$orange: #F6A028;
$black: #1a1a1a;
$blue: #083651;
$white: #ffffff;
$gray: #9D9D9C;
$gray-light: #EDEDED;
$black-light: lighten($gray, 16%);
$red: red;

$body-bg: rgb(248, 248, 248);

// Typography
$font-family-main: 'Inter', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
