@import 'mixins';
@import 'var';
@import 'fonts';

body, html {
  margin: 0;
  padding: 0;
}

html {
  position: relative;
  font-size: pxtorem(16);
  font-family: $font-family-main;
  min-width: 100%;
  height: 100vh;
}

body {
  background-color: $body-bg;
  color: $white;
  line-height: $line-height-base;
  min-width: 100%;
  height: 100vh;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

*,
::after,
::before {
  box-sizing: border-box;
}

hr {
  background-color: $white;
  height: 1.25rem;
  width: 15vw;
  margin: pxtorem(20) 0;
}

h1, h2, h3 {
  padding: 0;
  margin: 0;
}

h1 {
  @include font-size(42, 50, 42);
  line-height: 1.3;
  @include font-weight(900);
}

h2 {
  margin-bottom: 5px;
}

ul, li {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $white;

  &:hover, &:focus, &:active {
    color: $orange;
  }
}

p {
  @include font-size(16, 16, 16);
}

.icon {
  @include font-size(36, 36, 36);
}

.btn__group {
  @include flexbox();
  flex-wrap: wrap;
  flex-flow: column;

  .btn:first-child {
    margin-bottom: pxtorem(12);
  }

  @media screen and (min-width: pxtorem(786)) {

    flex-flow: row;

    .btn:first-child {
      margin-right: pxtorem(8);
    }

    .btn:last-child {
      margin-left: pxtorem(8);
    }

  }

}

.btn {
  @include flexbox();
  align-self: baseline;
  cursor: pointer;
  width: calc(100% - 16px);
  height: auto;
  background-color: #2f3033;
  border: pxtorem(2) solid transparent;
  border-radius: 0;
  padding: pxtorem(16);
  text-decoration: none;
  font-weight: 800;
  color: $white;
  transition: background-color .2s ease-in-out;
  justify-content: center;
  align-items: center;
  @include font-size(16, 16, 16);

  @media screen and (min-width: pxtorem(786)) {
    width: calc(50% - 16px);
  }

  &.btn {
    &--primary {
      background-color: $blue;
    }
  }

  &.btn {
    &--outlined {
      background-color: transparent;
      border-color: $white;

    }
  }

}

.container {
  @include flexbox(flex);
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  //height: 80vh;
  transition: margin-left 0.3s;
  max-width: pxtorem(810);
  width: 90%;

  @media screen and (min-width: pxtorem(991)) {
  }
}

header {
  padding: pxtorem(20) 0;
  max-width: 100%;
  width: calc(100% - 40px);
  margin: 0 pxtorem(40);
  @include flexbox(flex);
  justify-content: space-between;

  a {
    @include flexbox(flex);

    svg {
      width: pxtorem(160);
    }

  }

  nav {
    @include flexbox(flex);

    ul {
      @include flexbox(flex);

      li {
        list-style: none;
        color: $white;
        margin: 0 pxtorem(20) 0 0;
        @include flexbox(flex);
        align-self: center;

        &:last-child {
          margin: 0;
        }

        a {
          color: $white;
          font-size: pxtorem(14);
        }
      }
    }
  }
}

#app {
  @include flexbox(flex);
  flex-flow: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.app__background--overlay {
  background-color: $blue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro {
  @include flexbox(flex);
  @include flex-direction(column);
  width: 100%;

  @media screen and (min-width: 61.9375rem) {
    width: 80%;
    margin: 0 pxtorem(45);
  }

  .intro__subtxt {
    margin: 0;
    color: $orange;
    @include font-size(20, 20, 20);
    @include font-weight(800);
    text-transform: uppercase;
  }

  .intro__headline {

    &:after {
      width: 40%;
      height: pxtorem(10);
      background-color: $white;
      margin: pxtorem(20) 0 pxtorem(30);
      content: "";
      display: block;
    }
  }

  p {
    margin: 0 0 pxtorem(40);
  }
}

.content {
  @include flexbox(flex);
  flex-flow: column;

  h1 {
    margin-bottom: pxtorem(40);
  }

  p {
    @include font-size(18, 18, 18);
  }

  ul {
    li {
      @include font-size(18, 18, 18);
    }
  }

  a {
    text-decoration: none;
    position: relative;
    display: inline-block;

    &:before {
      z-index: 10;
      content: "";
      position: absolute;
      display: inline-block;
      left: 0;
      bottom: -2px;
      background-color: $orange;
      height: 2px;
      width: 0%;
      transition: all 1000ms cubic-bezier(.175, .885, .32, 1.075);
    }

    &:after {
      content: "";
      @include flexbox(flex);
      position: absolute;
      left: 0;
      bottom: -2px;
      background-color: #eee;
      height: 2px;
      width: 100%;
    }

    &:hover, &:active, &:focus {
      color: $white;

      &:before {
        width: 100%;
      }
    }
  }

  address {
    font-style: normal;
    @include font-size(18, 18, 18);
    margin-top: pxtorem(16);
  }
}

footer {
  @include flexbox(flex);
  flex-flow: row wrap;
  width: calc(100% - 40px);
  padding: pxtorem(16);
  max-width: 100%;
  margin: 0 pxtorem(40);
  justify-content: space-between;

  p {
    margin: 0;
    padding: 0;
    font-size: pxtorem(14);
  }

  nav {
    @include flexbox(flex);

    ul {
      @include flexbox(flex);

      li {
        list-style: none;
        color: $white;
        margin: 0 pxtorem(20) 0 0;

        &:last-child {
          margin: 0;
        }

        a {
          color: $white;
          font-size: pxtorem(14);
        }
      }
    }
  }
}
